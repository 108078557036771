import React, { Component } from 'react';
import { Toast } from 'primereact/toast';
import { notificationEvent } from '../service/NotificationService';

export default class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentWillMount() {
    notificationEvent.on('NOTIFY', this.showNotification)
  }

  showNotification = ({severity, summary, detail, sticky=false}) => { 
    this.toast.show({severity, summary, detail, sticky});
  }

  render() {
    return <Toast ref={(el) => this.toast = el} />
  }

  componentWillUnmount() {
    notificationEvent.removeListener('NOTIFY', this.showNotification)
  }
}
