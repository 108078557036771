import EventEmitter from 'eventemitter3';

export const notificationEvent = new EventEmitter();

export function showNotification(severity, summary, detail) {
  notificationEvent.emit('NOTIFY', {severity, summary, detail});
}

export function showInfoNotify(detail, title = 'Notify') {
  notificationEvent.emit('NOTIFY', {severity: 'info', summary: title, detail});
}

export function showWarnNotify(detail, title = 'Warning') {
  notificationEvent.emit('NOTIFY', {severity: 'warn', summary: title, detail});
}

export function showErrorNotify(detail, title = 'Error') {
  notificationEvent.emit('NOTIFY', {severity: 'error', summary: title, detail});
}

export function showSuccessNotify(detail, title = 'Success') {
  notificationEvent.emit('NOTIFY', {severity: 'success', summary: title, detail});
}

export function showUserNotification(message) {
  notificationEvent.emit('USER_NOTIFICATION', message);
}