import React, {Component} from 'react';
import '../login/Login.scss';
import {Password} from 'primereact/password';
import { Button } from 'primereact/button';
import { saveChangePassword } from '../service/UserService';
import { showNotification } from '../core/service/NotificationService';
import { Message } from 'primereact/message';

export default class ResetPasswordLink extends Component {
    constructor() {
        super();
        this.state = {
            data: {},
            errors: {}
        }
    }

    componentDidMount() {
        this.getParams();
    }

    getParams = () => {
        let query = new URLSearchParams(window.location.href);
        let token = query.get('t');
        this.setState({
            data: {...this.state.data,
                token: token
            }
        });
    }

    handleChangePassword = () => {
        this.setState({ errors: {} })
        saveChangePassword(this.state.data)
        .then(res => {
            if(!res.errorCode){
                showNotification('success', 'Success Message', 'New password has been changed');
                this.props.history.push('/login');
            }else {
                // showNotification('error', 'Error Message', res.errorMessage);
                if(res.errorCode===400) this.setState({errors: res.errorObj})
            }
        })
    }

    render() {
        let styleError ={color: '#cc0000'};
        return (
            <div className="login-body">
                <div className="login-panel p-fluid">
                    <div className="login-panel-header">
                        {/* <img className="logo" src="assets/fromlabs/logo.png" alt="Logo"/> */}
                        <div style={{margin:"auto", textAlign:"center", color: "#ffffff"}}>
                            <h1>Set New Password</h1>
                        </div>
                    </div>
                    <div className="login-panel-content">
                        <div className="p-grid">
                            {this.state.errors.token && <div className="p-col-12 p-c "><Message severity="warn" text={this.state.errors.token} /></div>}
                            <div className="p-col-12">
                                <label>New password</label>
                                <Password value={this.state.data.newPassword} onChange={(e) => this.setState({data: {...this.state.data, newPassword: e.target.value}})}/>  
                                <div className="p-form-error" style={styleError}>{this.state.errors.password}</div>
                            </div>
                            <div className="p-col-12">
                                <label>Confirm New password</label>
                                <Password value={this.state.data.confirmPassword} feedback={false} onChange={(e) => this.setState({data: {...this.state.data, confirmPassword: e.target.value}})} />   
                                <div className="p-form-error" style={styleError}>{this.state.errors.confirmPassword}</div>
                            </div>
                            <div className="p-col-12">
                                <Button label="Submit" onClick={this.handleChangePassword}/>
                            </div>
                            {/* <div className="p-col-12 ">
                                <Button label="Login" onClick={() => {window.open('/login'); window.close()}}/>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}