import React from 'react'

import { Route, Switch, Redirect } from 'react-router-dom'
import lazyLoad from './core/components/lazyLoad'
import ResetPasswordLink from './reset-password/ResetPasswordLink.js'

const Login = lazyLoad(() => import('./login/Login'))
const MainApp = lazyLoad(() => import ('./MainApp'));

export const Main = () => {
  return (
    <Switch>
      <Route path="/confirm_change_pass" component={ResetPasswordLink}/>   
      <Route path="/login" component={Login}/>
      <Route path="/" component={MainApp}/>
    </Switch>
  )
}
