import axios from "axios";
import querystring from 'query-string'
import {API_BASE_URL} from "../constants";
import qs from 'qs';

const SCP_BASE_URL = API_BASE_URL;

export function getUser(id){
    return axios.get(`${SCP_BASE_URL}/users/${id}`).then(res => res.data).catch(error => console.log(error))
}

export function getAccountPersonalFields(){
    return axios.get(`${SCP_BASE_URL}/users/get-account-personal-field`).then(res => res.data).catch(error => console.log(error))
}

export function getUserAccount() {
    return axios.get(`${SCP_BASE_URL}/users/get_user`).then(res => res.data).catch(error => console.log(error))
}

export function updateUserAccount(data) {
    return axios.post(`${SCP_BASE_URL}/users/update_user`, data).then(res => res.data).catch(error => console.log(error))
}

export function createUserAccount(data){
    return axios.post(`${SCP_BASE_URL}/users/create_account`, data).then(res => res.data).catch(error => console.log(error))
}

export function resetPassword(accountName){
    return axios.get(`${SCP_BASE_URL}/users/reset-password`, {params: {account: accountName}}).then(res => res.data).catch(error => console.log(error))
}

export function saveChangePassword(data) {
    return axios.post(`${API_BASE_URL}/scp/api/users/save-change-pass`, 
        querystring.stringify({...data})
    )
    .then(res => res.data).catch(error => console.log(error));
}

export function recoverUsername(email){
    return axios.get(`${SCP_BASE_URL}/users/recover-username`, {params: {email}}).then(res => res.data).catch(error => console.log(error))
}

export function confirmAccountVerify(data){
    return axios.get(`${SCP_BASE_URL}/users/confirm-account-verification`, {params: {...data}}).then(res => res.data).catch(error => console.log(error))
}

export function sendMailVerifyAccount(username){
    return axios.post(`${SCP_BASE_URL}/users/send-mail-account-verification`, querystring.stringify({account: username})).then(res => res.data).catch(error => console.log(error))
}

export function getUserCoupons() {
    return axios.get(`${SCP_BASE_URL}/events/coupon/load`).then(res => res.data).catch(error => console.log(error))
}

export function getUserTickets() {
    return axios.get(`${SCP_BASE_URL}/events/ticket/load`).then(res => res.data).catch(error => console.log(error))
}

export function getUserProfile(){
    return axios.get(`${SCP_BASE_URL}/users/profile`).then(res => res.data).catch(error => console.log(error))
}

export function resetPasswordProfile(data){
    return axios.post(`${SCP_BASE_URL}/users/profile/save-change-pass`, querystring.stringify({...data})).then(res => res.data).catch(error => console.log(error))
}

export function saveUserProfile(data){
    return axios.post(`${SCP_BASE_URL}/users/profile`, data).then(res => res.data).catch(error => console.log(error))
}

export function getEventSessionByUser(){
    return axios.get(`${SCP_BASE_URL}/users/profile/sessions`).then(res => res.data).catch(error => console.log(error))
}

export function getEventSessionAvailableByUser(filter){
    return axios.get(`${SCP_BASE_URL}/users/profile/sessions/available`, {params: {...filter}, paramsSerializer: params => qs.stringify(params, {arrayFormat: 'repeat'})})
    .then(res => res.data).catch(error => console.log(error))
}

export function getAllUsers(searchValue, type = '', id = null, refId = null){
    return axios.post(`${SCP_BASE_URL}/users/list`,
        querystring.stringify({
            search: searchValue,
            type: type,
            id: id,
            refId: refId
        })
    )
    .then(res => res.data).catch(error => console.log(error));
}

export function saveUserAvatar(data){
    return axios.post(`${SCP_BASE_URL}/users/avatar`, data)
        .then(res => res.data)
}

export function getAddressesByUser(userId){
    return axios.post(`${SCP_BASE_URL}/users/${userId}/addresses`).then(res => res.data)
}
