import axios from "axios";
import querystring from 'querystring';
import { LOGIN_BASE_URL, TENANT_ID, CLIENT_CREDENTIALS, CLIENT_ID } from '../constants';
import { saveLoginInfo, getRefreshToken, parseJwt } from '../core/security/auth';

let API_BASE_URL = LOGIN_BASE_URL;

export async function login(username, password) {
    const res = await axios.post(`${API_BASE_URL}/login`, querystring.stringify({
        username: username,
        password: password,
        grant_type: 'password',
        client_id: CLIENT_ID
    }), {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        auth: {
            username: CLIENT_ID,
            password: CLIENT_CREDENTIALS
        },
    });
    if (!res.data.error) {
        const me = await getSession(res.data);
        saveLoginInfo(res.data, me);
        // if(res.data.access_token) {
        //     localStorage.setItem("access_token", res.data.access_token);
        //     const tokenDetails = parseJwt(res.data.access_token);
        
        //     localStorage.setItem('tokenExp', tokenDetails.exp);

        //     return res.data;
        // }
    }
    return res.data;
}

async function getSession({ access_token }) {
    const res = await axios.get(`${API_BASE_URL}/session`, {headers: {'Authorization': `Bearer ${access_token}`}});
    return res.data;
}

export async function refreshToken() {
    const res = await axios.post(`${API_BASE_URL}/login`, 
        querystring.stringify({
            refresh_token: localStorage.getItem('refresh_token'),
            grant_type: 'refresh_token',
            client_id: CLIENT_ID
        }), {
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            auth: {
                username: CLIENT_ID,
                password: CLIENT_CREDENTIALS
            },
    });
    if (!res.data.error) {
        // const me = await getSession(res.data);
        saveLoginInfo(res.data)
    }
    return res.data;
}
