import React from 'react'
import ReactDOM from 'react-dom'
import 'core-js/stable'

import 'primereact/resources/primereact.min.css'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import 'tippy.js/themes/light.css'

import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import { Main } from './Main'
import './core/interceptors/interceptors'

import { I18nextProvider } from 'react-i18next'
import { reactI18nextModule } from "react-i18next"
import i18next from 'i18next'

import message_en from "./translations/message_en.json"
import message_vi from "./translations/message_vi.json"
import Notification from './core/components/Notification'

i18next
  .use(reactI18nextModule)
  .init({
    interpolation: { escapeValue: false },
    lng: 'en',
    fallbackLng: "en",
    resources: {
      'en': {
        message: message_en
      },
      'en-US': {
        message: message_en
      },
      'en-UK': {
        message: message_en
      },
      'vi': {
        message: message_vi
      }
    },
  }
  );

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <BrowserRouter>
      {/* <Message /> */}
      <Main/>
      <Notification/>
    </BrowserRouter>
  </I18nextProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()