export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_PATH_SCP;
export const CLIENT_CREDENTIALS = process.env.REACT_APP_CLIENT_CREDENTIALS;
export const TENANT = process.env.REACT_APP_TENANT;
export const TENANT_ID = process.env.REACT_APP_TENANT_ID;
export const USER_ID = process.env.REACT_APP_USER_ID;
// export const USERNAME = process.env.REACT_APP_USERNAME;

export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const TICKET_URL = 'https://register.jaktent.com/tickets?s=';

let loginURL = '';
if (process.env.REACT_APP_LOGIN_BASE_URL) {
  loginURL = process.env.REACT_APP_LOGIN_BASE_URL;
} else {
  loginURL = process.env.REACT_APP_API_BASE_URL;
};
export const LOGIN_BASE_URL = loginURL;

let pimURL = '';
if (process.env.REACT_APP_PIM_BASE_URL) {
  pimURL = process.env.REACT_APP_PIM_BASE_URL;
} else {
  pimURL = process.env.REACT_APP_API_BASE_URL;
};
export const PIM_BASE_URL = pimURL + process.env.REACT_APP_PATH_PIM;

let paymentURL = '';
if (process.env.REACT_APP_PAYMENT_BASE_URL) {
  paymentURL = process.env.REACT_APP_PAYMENT_BASE_URL;
} else {
  paymentURL = process.env.REACT_APP_API_BASE_URL;
};
export const PAYMENT_BASE_URL = paymentURL + process.env.REACT_APP_PATH_PAYMENT;

let configServices = '';
if(process.env.REACT_APP_CONFIGURATION_BASE_URL){
  configServices = process.env.REACT_APP_CONFIGURATION_BASE_URL;
} else {
  configServices = process.env.REACT_APP_API_BASE_URL;
}
export const CONFIGURATION_BASE_URL = configServices;

export const TABLE_PAGING_LENGTH = [
  { label: "5", value: 5 },
  { label: "10", value: 10 },
  { label: "25", value: 25 },
  { label: "50", value: 50 },
  { label: "100", value: 100 }
];

export const GENDERS = [
  { label: "Any", value: "Any" },
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Non-binary", value: "NonBinary" }
];

export const FIELD_TYPE = {
  text: "text",
  number: "number",
  email: "email",
  phone: "phone",
  select: "select",
  radio: "radio",
  checkbox: "checkbox",
  boolean: "yes_no",
  textarea: "textarea",
  file: "file",
  date: "date",
  time: "time",
  waiver: "waiver",
  extra: "extra"
};

export const FIELD_EXPRESSION_TYPE = {
  none: 0,
  pattern: 1,
  maskString: 2
};

export const FIELD_DISPLAY_TYPE = {
  dropdown: "dropdown",
  checkbox: "checkbox",
  radio: "radio",
  textbox: "textbox",
  file: "file"
};

export const FIELD_DIRECTION_TYPE = {
  horizontal: 'horizontal',
  vertical: 'vertical'
}

export const SESSION_VIEW_TYPE = {
  zoom_metting: 'zoom_metting',
  on_demand: 'on_demand',
  pre_recorded: 'pre_recorded',
  recorded: 'recorded'
}

export const CONTENT_LAYOUT_COLUMN = {
  ONE_COLUMN: 'one_column',
  TWO_COLUMNS: 'two_columns',
  THREE_COLUMNS:  'three_columns'
} 

export const CONTENT_LAYOUT_COLUMN_TYPE = { 
  one_col_12: 'one_col_12',
  two_col_6_6: 'two_col_6_6',
  two_col_4_8: 'two_col_4_8',
  two_col_8_4: 'two_col_8_4',
  three_col_4_4_4: 'three_col_4_4_4',
  three_col_3_3_6: 'three_col_3_3_6',
  three_col_6_3_3: 'three_col_6_3_3',
  three_col_3_6_3: 'three_col_3_6_3'
}

export const CONTENT_COMPONENT_TYPE = {
  free_text : 'free_text',
  slider: 'slider',
  weather_widget: 'weather_widget',
  product_widget: 'product_widget',
  facebook_embed: 'facebook_embed',
  twitter_embed: 'twitter_embed',
  google_embed: 'google_embed',
  blog_listing: 'blog_listing',
  speaker: 'speaker',
  event_ticket: 'event_ticket'
}

export const MENU_TYPE = {
  PUBLIC_PAGE: "PUBLIC_PAGE", 
  PREDEFINE_PAGE: "PREDEFINE_PAGE", 
  LINK: "LINK", 
  BLANK: "BLANK"
}

export const CONTENT_HERO_BLOCK_TYPE = { 
  HTML: 'HTML',
  IMAGE: 'IMAGE',
  SLIDER: 'SLIDER',
}

export const CONTENT_HERO_DISPLAY = { 
  none: 'none',
  left: 'left',
  both: 'both',
}

export const DYNAMIC_PAGE_PREFIX = "/page";
export const PATH_PREFIX = "/app";

export const PAGE_CONTENT_KEY_PREDEFINED = {
  events: 'events', 
  event_sessions: 'event_sessions',
  cart_view: 'cart/checkout',
  cart_complete: 'cart/complete',
  user_profile: 'profile',
  session_exchange: 'event_sessions/exchange',
  conference: 'conference',
  conference_meeting: 'conference/meeting',
  conference_ondemand: 'conference/on-demand',
  ticket: 'tickets',
  speaker: 'speakers',
}

export const PAYMENT_USE_MODE = { 
  TEST: 'TEST',
  PRODUCTION: 'PRODUCTION'
}

export const ORDER_STATE = {
  cart: {value: 'cart', label: 'Cart'},
  new_order: {value: 'new_order', label: 'New'},
  viewed: {value: 'viewed', label: 'Viewed'},
  canceled: {value: 'canceled', label: 'Canceled'}
};

export const PAYMENT_STATE = {
  pending: {value: 'pending', label: 'Pending'},
  awaiting: {value: 'awaiting', label: 'Awaiting payment'},
  completed: {value: 'completed', label: 'Completed'},
  cancelled: {value: 'cancelled', label: 'Cancenlled'}
};

export const CHECKOUT_STATE = {
  cart: 'cart',
  waiting_participant: 'waiting_participant',
  waiting_address: 'waiting_address',
  waiting_checkout: 'waiting_checkout',
  completed: 'completed'
};

export const SHIPPING_STATE = {
  pending: 'pending',
  preparing: 'preparing',
  ready: 'ready',
  shipping: 'shipping',
  shipped: 'shipped',
  completed: 'completed'
}

export const CHANNEL_TYPE = {
  program: 'program',
  event: 'event',
  membership: 'membership',
  donation: 'donation'
};

export const ORDER_ITEM_STATUS = {
  added: 'added',
  confirming: 'confirming',
  confirmed: 'confirmed',
  pending: 'pending',
  waiting: 'waiting',
  processing: 'processing',
  completed: 'completed',
  canceled: 'canceled'
}

export const ORDER_ITEM_STATUSES = [
  {value: ORDER_ITEM_STATUS.pending, label:'res.mgt.order_item_status_pending'},
  {value: ORDER_ITEM_STATUS.waiting, label:'res.mgt.order_item_status_waiting'},
  {value: ORDER_ITEM_STATUS.processing, label:'res.mgt.order_item_status_processing'},
  {value: ORDER_ITEM_STATUS.completed, label:'res.mgt.order_item_status_completed'},
  {value: ORDER_ITEM_STATUS.canceled, label:'res.mgt.order_item_status_canceled'}
]

export const CURRENCIES = [
  {label: 'USD', value: 'USD'},
  {label: 'EUR', value: 'EUR'},
  {label: 'SGD', value: 'SGD'},
  {label: 'IDR', value: 'IDR'},
];

export const CURRENCY_OBJECT = {
  USD: {symbol: '$', decimal: 2},
  EUR: {symbol: '€', decimal: 2},
  SGD: {symbol: '$', decimal: 2},
  IDR: {symbol: 'Rp', decimal: 0}
}

export const PRICING_MODE = {
  normal: 'normal',
  included_tax: 'included_tax'
}

export const DEPOSIT_TYPE = {
  installment: {value: 'installment', label: 'Installment'},
  pledge: {value: 'pledge', label: 'Pledge'}
}

export const EVENT_SESSION_TYPES = {
  live: 'live',
  on_demand: 'on_demand'
};

export const EVENT_SESSION_CONFERENCE_TYPES = {
  zoom: 'zoom'
}

export const RESOURCE_TYPE = {
  moderator: 'moderator',
  speaker: 'speaker'
}

export const USER_REGISTER_AS = {
  student: 'student',
  tpStaff: 'tp_staff',
  parent: 'parent',
  industryPartner: 'industry_partner',
  others: 'others'
}

export const USER_REGISTER_AS_LIST = [
  {label: 'Student', value: USER_REGISTER_AS.student},
  {label: 'TP Staff', value: USER_REGISTER_AS.tpStaff},
  {label: 'Parent', value: USER_REGISTER_AS.parent},
  {label: 'Industry Partner', value: USER_REGISTER_AS.industryPartner},
  {label: 'Others', value: USER_REGISTER_AS.others}
]

export const USER_ACTIONS = {
  like: 'like',
  share: 'share'
}

export const PRODUCT_IMAGE_TYPE = {
  teaser: 'teaser',
  gallery: 'gallery',
}

export const EVENT_TYPE = {
  challenge : 'challenge',
  camping: 'camping',
  phasing: 'phasing',
  conference: 'conference'
}

export const ATTRIBUTE_FIELD_SET_TYPE = {
  personal: 'personal',
  custom_field: 'custom_field',
  waiver: 'waiver',
  program: 'program',
  newsletter: 'newsletter',
  survey: 'survey',
}

export const PRODUCT_CLASSIFICATION = {
  simple: 'simple',
  variable: 'variable',
  combo: 'combo',
  configuration: 'configuration'
}

export const PRODUCT_OPTION_TYPE = {
  variant: 'variant',
  properties: 'properties'
}

export const PROGRAM_WORK_TYPE = {
  simple: 'simple',
  dw: 'dw',
}

export const PROGRAM_WORK_TYPES = [
  {label: 'Simple', value: PROGRAM_WORK_TYPE.simple},
  {label: 'Daily/Weekly', value: PROGRAM_WORK_TYPE.dw}
]

export const WEEK_DAY_SHORT = {
  mon: 'mon',
  tue: 'tue',
  wed: 'wed',
  thu: 'thu',
  fri: 'fri',
  sat: 'sat',
  sun: 'sun',
}

export const TAXON_TYPE = {
  product: {value: 'product', label: 'Product'},
  category: {value: 'category', label: 'Category'},
  branch: {value: 'branch', label: 'Branch'},
  location: {value: 'location', label: 'Location'},
  asset: {value: 'asset', label: 'Asset'},
}

export const PAYMENT_GATEWAY = {
  ONSITE: 'ONSITE',
  STRIPE: 'STRIPE',
  SQUARE: 'SQUARE',
  PAYPAL: 'PAYPAL',
  MIDTRANS: 'MIDTRANS',
  HITPAY: 'HITPAY',
  BRAINTREE: 'BRAINTREE',
  SLIM_CD: 'SLIM_CD',
  LUMINATE_DONATION_API: 'LUMINATE_DONATION_API',
  RED_DOT: 'RED_DOT'
};

export const PAYMENT_METHOD = {
  online: {value: 'online', label: 'Online Pay'},
  cash: {value: 'cash', label: 'Cash'},
  voucher: {value: 'voucher', label: 'Voucher'},
  cheque: {value: 'cheque', label: 'Cheque'},
  swipe_card: {value: 'swipe_card', label: 'Swipe Card'},
  user_credit: {value: 'user_credit', label: 'User Credit'},
  bank_transfer: {value: 'bank_transfer', label: 'Bank Transfer'},
  credit_card: {value: 'credit_card', label: 'Credit Card'},
  card: {value: 'card', label: 'Card'},
  paynow_online: {value: 'paynow_online', label: 'Paynow Online'},
  alipay: {value: 'alipay', label: 'Ali Pay'},
  wechat: {value: 'wechat', label: 'Wechat'},
  grabpay: {value: 'grabpay', label: 'Grab Pay'}
};

export const PARTICIPANT_STATUS = {
  INCOMPLETE: {value: 'INCOMPLETE', label: 'INCOMPLETE'},
  PENDING: {value: 'PENDING', label: 'PENDING'},
  PROCESSING: {value: 'PROCESSING', label: 'PROCESSING'},
  WAITING: {value: 'WAITING', label: 'WAITING'},
  UNPAID: {value: 'UNPAID', label: 'UNPAID'},
  COMPLETED: {value: 'COMPLETED', label: 'COMPLETED'},
  CANCELED: {value: 'CANCELED', label: 'CANCELED'},
}

export const PRICE_APPLY_FOR = {
  normal: 'normal',
  membership: 'membership',
  staff_full: 'staff_full',
  staff_part: 'staff_part'
}

export const APP_FEP = 'fep';

export const TAXONOMY_TYPE = {
  product: {value: 'product', label: 'Product'},
  category: {value: 'category', label: 'Category'},
  branch: {value: 'branch', label: 'Branch'},
  location: {value: 'location', label: 'Location'},
  asset: {value: 'asset', label: 'Asset'},
};

export const PRODUCT_TYPE = {
  program: {value: 'program', label: 'Program'},
  rental: {value: 'rental', label: 'Rental'},
  session: {value: 'session', label: 'Session'},
  addon: {value: 'addon', label: 'Addon'},
  membership: {value: 'membership', label: 'Membership'},
  donation: {value: 'donation', label: 'Donation level'}
}

export const PRODUCT_PRICE_UNIT = {
  min: {value: 'min', label: 'min'},
  hr: {value: 'hr', label: 'hr'},
  day: {value: 'day', label: 'day'},
  month: {value: 'month', label: 'month'},
  year: {value: 'year', label: 'year'}
}

export const PRICE_APPLY_TYPE = {
  normal: {value: 'normal', label: 'Normal'},
  membership: {value: 'membership', label: 'Membership'},
  staff: {value: 'staff', label: 'Staff'},
  staff_part: {value: 'staff_part', label: 'Staff Part Time'}
}

export const VARIANT_OPTION_TYPE = {
  option: 'option',
  custom: 'custom',
}

export const TIME_UNIT_TYPE = {
  min: {value: 'minute', label: 'Minute'},
  hr: {value: 'hours', label: 'Hours'}
}

export const APPLICATION_COMPONENT_TYPE = {
  free_text: {value: 'free_text', label: 'Free Text'},
  input_field: {value: 'input_field', label: 'Input Field'}
}